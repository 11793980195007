import { isEmpty } from "lodash";

const countryToCurrency = {
  KR: "KRW", // 대한민국 - 원
  JP: "JPY", // 일본 - 엔
  CN: "CNY", // 중국 - 위안
  TW: "TWD", // 대만 - 신 대만 달러

  // 동남아시아 국가들
  SG: "SGD", // 싱가포르 - 싱가포르 달러
  MY: "MYR", // 말레이시아 - 링깃
  ID: "IDR", // 인도네시아 - 루피아
  TH: "THB", // 태국 - 바트
  VN: "VND", // 베트남 - 동
  PH: "PHP", // 필리핀 - 페소
  KH: "KHR", // 캄보디아 - 리엘
  MM: "MMK", // 미얀마 - 캬트
  LA: "LAK", // 라오스 - 킵
  BN: "BND", // 브루나이 - 브루나이 달러
  TL: "USD", // 동티모르 - 미국 달러 (공식 화폐)

  // 기타 주요 국가
  US: "USD", // 미국 - 달러
  EU: "EUR", // 유럽연합 - 유로
  GB: "GBP", // 영국 - 파운드
  AU: "AUD", // 호주 - 호주 달러
  CA: "CAD", // 캐나다 - 캐나다 달러
  IN: "INR", // 인도 - 루피
  HK: "HKD", // 홍콩 - 홍콩 달러
};

export const getCurrencyCode = (countryCode) => {
  if (isEmpty(countryCode)) {
    return "KRW"
  }
  return countryToCurrency[countryCode.toUpperCase()];
};

export const intlConfig = (user) => {
  if (user) {
    return { locale: user.locale, currency: getCurrencyCode(user.country) }
  } else {
    return null;
  }
}
