import { Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerOverlay } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import React from "react";
import styled from "styled-components";
import LightColors from "../../constants/LightColors";
import PrimaryButton from "../buttons/PrimaryButton";

const MenuItem = styled.div`
  padding: 16px 0px;
  border-radius: 8px;
  border: 1px solid ${LightColors.separatorlv1};
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: ${LightColors.textmainlv2};
`;

const tradeMethods = [
  "direct",
  "standardDelivery",
  "slowDelivery",
]

export default function TradeMethodBottomDrawer({ isOpen, onClose, onSelectTradeMethod }) {
  const [selectedMethod, setSelectedMethod] = React.useState(null)

  const handleSelect = (method) => {
    setSelectedMethod(method)
  }

  const handleComplete = () => {
    onSelectTradeMethod(selectedMethod)
    onClose()
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
      <DrawerOverlay />
      <DrawerContent
        motionProps={{
          variants: {
            enter: {
              y: "0%",
              transition: { duration: 0.15 },
            },
            exit: {
              y: "100%",
              transition: { duration: 0.1 },
            },
          }
        }}
        style={{
          paddingTop: "24px",
          maxWidth: "calc(var(--chakra-sizes-xl))",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          left: "",
          right: ""
        }}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 24px 16px 24px',
          borderBottom: `1px solid ${LightColors.separatorlv1}`
        }}>
          <span style={{ fontSize: '18px', fontWeight: '600' }}>{"거래방식"}</span>
          <span
            style={{
              fontSize: '14px',
              color: LightColors.textmainlv2,
              cursor: 'pointer'
            }}
            onClick={() => {
              setSelectedMethod(null)
              onSelectTradeMethod(null)
              onClose()
            }}
          >
            {t("TradeMethod.any")}
          </span>
        </div>
        <DrawerBody>
          <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "16px" }}>
          {tradeMethods.map((method) => (
            <MenuItem
              key={method.key}
              onClick={() => handleSelect(method)}
              style={{
                borderColor: selectedMethod === method
                  ? LightColors.primary
                  : LightColors.separatorlv1,
                backgroundColor: 'transparent',
                color: selectedMethod === method
                  ? LightColors.primary
                  : LightColors.textmainlv2
              }}
            >
              {t(`TradeMethod.${method}`)}
            </MenuItem>
          ))}
          </div>
        </DrawerBody>
        <DrawerFooter>
          <PrimaryButton
            disabled={isEmpty(selectedMethod)}
            text={t("done")}
            onClick={handleComplete}
            style={{
              width: "100%",
              padding: "16px 24px",
            }}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
