import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  SimpleGrid
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import LightColors from "../../constants/LightColors";
import { ReactComponent as CheckedIcon } from "../../icons/check.svg";
import useArtistEventsQuery from "../api/queries/useArtistEventsQuery";
import useUserQuery from "../api/queries/useUserQuery";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryLineButton from "../buttons/SecondaryLineButton";
import { EventNameBox } from "../event/EventNameBox";
import EventsGroupList from "../lists/EventsGroupList";
import { ListRenderer } from "../lists/ListRenderer";
import PriamryRadio from "../radio/PriamryRadio";
import GridSkeletons from "../skeletons/GridSkeletons";

// Styled Components
const DrawerTitleRow = styled.div`
  padding: 38px 24px 12px 24px;
  white-space: pre-wrap;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: ${LightColors.textmainlv1};
`;

const DrawerContentWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`

const SectionTitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  color: ${LightColors.textmainlv2};
  padding-top: 8px;
  padding-bottom: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${LightColors.separatorlv1};
`;

const PhotoCardCategoryBox = styled.div`
  border-radius: 8px;
  border: ${(props) => (props.isChecked ? `1px solid ${LightColors.primary}` : `1px solid ${LightColors.separatorlv1}`)};
  background: #FFF;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  width: 100%;
`

const PhotoCardCategoryText = styled.div`
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`

const EventNameWrapper = styled.div`
  flex-grow: 1;
`

const EmptyText = styled.div`
  color: ${LightColors.onDisable};
  margin-top: 32px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
`

const ScrollListWrapper = styled.div`
  padding: 8px 0px;
`

const PHOTOCARD_CATEGORIES = [
  "default", "special", "md", "trading",
]

const TradingPhotoCardFilterBottomDrawer = ({ artistId, identityVerified=false, eventIds=[], photoCardCategories=[], isOpen, onClose, focusRef, onConfirm }) => {
  const [checkedIdentityVerified, setCheckedIdentityVerified] = useState(identityVerified);
  const [selectedEventIds, setSelectedEventIds] = useState(eventIds);
  const [selectedEventCategoryName, setSelectedEventCategoryName] = useState("all")
  const [selectedPhotoCardCategories, setSelectedPhotoCardCategories] = useState(photoCardCategories);

  const user = useUserQuery();
  // Fetching members and events
  const events = useArtistEventsQuery({artistId: artistId, photoCardCategories: selectedPhotoCardCategories});

  const onSelectEvent = (eventId) => {
    setSelectedEventIds((prev) =>
      prev.includes(eventId) ? prev.filter((id) => id !== eventId) : [...prev, eventId]
    );
  };

  const onSelectCategory = (category) => {
    setSelectedPhotoCardCategories((prev) =>
      prev.includes(category) ? prev.filter((prevCategory) => prevCategory !== category) : [...prev, category]
    );
  }

  const onSelectedEventCategoryName = (eventCategoryName) => {
    setSelectedEventCategoryName(eventCategoryName);
  }

  const initFilters = () => {
    setCheckedIdentityVerified(false);
    setSelectedPhotoCardCategories([]);
    setSelectedEventIds([]);
  }

  const handleConfirm = () => {
    onConfirm({
      identityVerified: checkedIdentityVerified,
      photoCardCategories: selectedPhotoCardCategories,
      eventIds: selectedEventIds,
    });
    onClose();
  };

  const filteredEvents = useMemo(() => {
    if (selectedEventCategoryName === 'all') {
      return events;  // Return all events when "all" is selected
    }
    return events?.filter(event => event.categoryName === selectedEventCategoryName);
  }, [events, selectedEventCategoryName]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="bottom" finalFocusRef={focusRef}>
      <DrawerOverlay />
      <DrawerContent
        motionProps={{
          variants: {
            enter: {
              y: "0%",
              transition: { duration: 0.15 },
            },
            exit: {
              y: "100%",
              transition: { duration: 0.1 },
            },
          }
        }}
        style={{
          maxWidth: "calc(var(--chakra-sizes-xl))",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          left: "",
          right: ""
        }}
      >
        <DrawerBody padding={0} minHeight={"60vh"} height={"60vh"} maxHeight={"60vh"}>
        <DrawerTitleRow>{t("TradingPhotoCardFilterBottomDrawer.title")}</DrawerTitleRow>
        <DrawerContentWrapper>
          {
            user?.country === "KR" &&
            <>
              <Row>
                <PriamryRadio
                  text={t("TradingPhotoCardFilterBottomDrawer.identityVerifiedTitle")}
                  isChecked={checkedIdentityVerified}
                  onChanged={() => setCheckedIdentityVerified(!checkedIdentityVerified)}
                />
              </Row>
            </>
          }
        </DrawerContentWrapper>
        <DrawerContentWrapper style={{paddingTop: "18px"}}>
        <SectionTitle>{t("TradingPhotoCardFilterBottomDrawer.categoryTitle")}</SectionTitle>
          <SimpleGrid columns={2} justifyItems={"center"} gap={"8px"} paddingTop={"10px"} paddingBottom={"18px"}>
            {
              PHOTOCARD_CATEGORIES.map((category) => {
                const isChecked = selectedPhotoCardCategories.includes(category);
                return(
                  <PhotoCardCategoryBox key={category} isChecked={isChecked} onClick={() => onSelectCategory(category)}>
                    {
                      isChecked &&
                      <CheckedIcon fill={LightColors.primary} width={"16px"} height={"16px"}/>
                    }
                    <PhotoCardCategoryText color={isChecked ? LightColors.primary : LightColors.pureblack}>
                      {t(`PhotoCardFilterBottomDrawer.${category}Category`)}
                    </PhotoCardCategoryText>
                  </PhotoCardCategoryBox>
                )
              })
            }
          </SimpleGrid>
        </DrawerContentWrapper>
        <DrawerContentWrapper>
          <SectionTitle>{t("TradingPhotoCardFilterBottomDrawer.eventTitle")}</SectionTitle>
        </DrawerContentWrapper>
        <ScrollListWrapper>
          <EventsGroupList
            events={events}
            selectedEventCategoryName={selectedEventCategoryName}
            onSelectedEventCategory={(eventCategoryName) => onSelectedEventCategoryName(eventCategoryName)}
          />
        </ScrollListWrapper>
        <DrawerContentWrapper>
          {
            <ListRenderer
              data={filteredEvents}
              emptyComponent={<EmptyText>{t("PhotoCardFilterBottomDrawer.emptyCategory")}</EmptyText>}
              skeletonComponent={
                <GridSkeletons
                  gridSize={1}
                  skeletonSize={10}
                  skeletonHeight={"48px"}
                  marginBottom={"8px"}
                />
              }
              renderList={(data) => (
                data.map((event) => (
                  <Row key={event.eventId}>
                    <EventNameWrapper>
                      <EventNameBox event={event} isDisabled={!selectedEventIds.includes(event.eventId)}/>
                    </EventNameWrapper>
                    <PriamryRadio
                      isChecked={selectedEventIds.includes(event.eventId)}
                      onChanged={() => onSelectEvent(event.eventId)}
                    />
                  </Row>
                ))
              )}
            />
          }
        </DrawerContentWrapper>
        </DrawerBody>
        <DrawerFooter paddingTop="10px" paddingBottom="calc(env(safe-area-inset-bottom) + 20px)" paddingX="20px" gap={"6px"}>
          <SecondaryLineButton
            text={t("PhotoCardFilterBottomDrawer.initButton")}
            style={{padding: "16px", border: "0px", fontSize: "17px"}}
            onClickButton={() => initFilters()}
          />
          <PrimaryButton
            fontWeight={600}
            fontSize={"17px"}
            lineHeight={"20px"}
            padding={"16px 34px"}
            width={"100%"}
            onClick={handleConfirm}
            text={t("confirm")}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default TradingPhotoCardFilterBottomDrawer;
