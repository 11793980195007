import baseUrl from '../../../utils/baseUrl'
import getToken from '../../../utils/getToken'

/**
 * @param {string} tradingPhotoCardId - 끌올할 거래 포토카드 ID 배열
 * @returns {Promise<Response>} - API 응답
 */
export default async function BumpTradingPhotoCard(tradingPhotoCardId) {
  return fetch(baseUrl() + `/api/v2/trading_photo_cards/${tradingPhotoCardId}/bump`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getToken()
    }
  })
}
