import { Image, Text, VStack } from '@chakra-ui/react';
import { t } from "i18next";
import { first, isEmpty, isNull } from "lodash";
import React from "react";
import colors from "../../constants/colors";
import LightColors from '../../constants/LightColors';
import { useNavigate } from 'react-router-dom';

const containerStyle = {
  width: "120px",
  height: "120px",
  padding: "8px",
  background: LightColors.surface,
  borderRadius: "12px",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}

const fullImageStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "12px",
  objectFit: "cover",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1
}

const thumbnailImageStyle = {
  aspectRatio: "55/87",
  height: "63px",
  borderRadius: "5px",
  zIndex: 2,
  position: "relative"
}

const textStyle = {
  color: "#222",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "14px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%"
}

const priceStyle = {
  color: colors.primary,
  fontSize: "13px",
  fontWeight: 600,
  lineHeight: "16px"
}

export default function CompactTradingPhotoCard({ tradingPhotoCard }) {
  const navigate = useNavigate();

  if (isEmpty(tradingPhotoCard)) return null;

  const imageUrl = first(tradingPhotoCard.mediumThumbnailUrls) || tradingPhotoCard.photoCard.mediumThumbnailUrl;

  const navigateTradingPhotoCardDeatail = () => {
    navigate(`/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}`)
  }

  return (
    <VStack spacing={1} align="center" width="120px" alignItems={"left"} onClick={navigateTradingPhotoCardDeatail}>
      {
        isEmpty(tradingPhotoCard.mediumThumbnailUrls) ?
        <div style={containerStyle}>
           <Image
            style={thumbnailImageStyle}
            src={tradingPhotoCard.photoCard.pictureUrl}
            alt={tradingPhotoCard.photoCard.memberName}
          />
        </div>
        :
        <div style={containerStyle}>
          <Image
            style={fullImageStyle}
            src={imageUrl}
            alt={tradingPhotoCard.photoCard.memberName}
          />
        </div>

      }
      <Text style={textStyle}>
        {t("photoCardDetailPage.title", {memberName: tradingPhotoCard.photoCard.memberName, categoryName: tradingPhotoCard.photoCard.categoryName, categoryEmoji: tradingPhotoCard.photoCard.categoryEmoji})}
      </Text>

      <Text style={textStyle}>
        {tradingPhotoCard.photoCard.eventName}
      </Text>

      {tradingPhotoCard.category === "sale" && (
        <Text style={priceStyle}>
          {isNull(tradingPhotoCard.price)
            ? t("tradingPhotoCardPage.offerPriceWithEmoji")
            : tradingPhotoCard.price}
        </Text>
      )}
    </VStack>
  );
}
