import React, { useEffect, useState } from "react";

import { Image, Spinner, useRadio } from '@chakra-ui/react';
import { t } from "i18next";
import { isEmpty } from "lodash";
import { formatValue } from 'react-currency-input-field';
import { Helmet } from "react-helmet-async";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useFilePicker } from "use-file-picker";

import useUserQuery from "../../components/api/queries/useUserQuery";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrow.right.small.svg";
import { intlConfig } from "../../utils/CurrencyUtil";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import PriamryRadio from "../../components/radio/PriamryRadio";
import TradeMethodBottomDrawer from "../../components/drawer/TradeMethodBottomDrawer";
import AddTradingPhotoCardsV2 from "../../components/api/mutations/AddTradingPhotoCardsV2.js";


const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  padding: 12px 0px;
  color: ${LightColors.textmainlv1};
`

const PriceText = styled.div`
  margin-top: 6px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${LightColors.textprimary};
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: calc(54px + env(safe-area-inset-top));
  padding-bottom: calc(128px + env(safe-area-inset-bottom));;
`

const PageRowContainer = styled.div`
  padding: 0px 24px 12px 24px;
  scroll-snap-align: start;
`

const ImageContainer = styled.div`
  width: 84px;
  height: 84px;
  margin-left: 12px;
  position: relative;
`;

const AddImageButton = styled.div`
  width: 84px;
  height: 84px;
  place-items: center;
  align-content: center;
  border-radius: 12px;
  border: 1px solid ${LightColors.separatorlv1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StickyContainer = styled.div`
  position: sticky;
  background: white;
`;

const HorizontalScrollContainer = styled.div`
  padding-top: 8px;
  display: flex;
  overflow-x: auto; /* 수평 스크롤 가능 */
  scrollbar-width: none; /* Firefox에서 스크롤바 숨기기 */
  -ms-overflow-style: none; /* Internet Explorer 및 Edge에서 스크롤바 숨기기 */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera에서 스크롤바 숨기기 */
  }
`;

const PriceContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: ${({ index }) => (index > 0 ? "5px" : "0")};
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(245, 245, 245, 1);
  border-radius: 8px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 4px;
  background-color: ${LightColors.primary};
  border-radius: 32px;
  padding: 5px;
  margin-right: 3px;
  margin-top: -8px;
  cursor: pointer;
  display: inline-flex;
`;

const AttachedImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
`;

const TextArea = styled.textarea`
  border-radius: 12px;
  border: 1px solid ${LightColors.separatorlv1};
  width: 100%;
  padding: 20px;
  resize: vertical;
  height: 120px;
  outline: none;
`;

const TradeMethodTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: ${LightColors.textmainlv1};
`

const TradeMethodDescription = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: ${LightColors.textmainlv3};
`

export default function ConfirmNewSalesTradingPhotoCardsPage() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const category = currentQueryParams.get("category");
  const artistId = currentQueryParams.get("artistId");
  const tradingPhotoCards = state?.newTradingPhotoCards

  const [imageFiles, setImageFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isNewItem, setIsNewItem] = useState(false);
  const [selectedTradeMethod, setSelectedTradeMethod] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [description, setDescription] = useState('')

  const user = useUserQuery();

  const { openFilePicker, filesContent } = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
  });

  const handleAddImageClick = () => {
    if (imageFiles.length < 10) {
      openFilePicker();
    }
  };

  useEffect(() => {
    if (filesContent.length > 0) {
      setImageFiles(filesContent);
    }
  }, [filesContent]);

  const onDone = async () => {
    if (!isUploading && user?.tradingUserProfile) {
      setIsUploading(true)
      try {
        const tradingPhotoCardsData = tradingPhotoCards.map(tradingPhotoCard => ({
          artistId: artistId,
          photoCard: {
            memberId: tradingPhotoCard.photoCard.memberId,
            photoCardId: tradingPhotoCard.photoCard.photoCardId
          },
          description: description,
          category: category,
          price: tradingPhotoCard.price,
          tradeMethod: selectedTradeMethod,
          isNewItem: isNewItem,
        }));

        const response = await AddTradingPhotoCardsV2(
          user.tradingUserProfile.userProfileId,
          tradingPhotoCardsData,
          imageFiles.map((imageFile) => imageFile.content),
        )

        if (response.ok) {
          navigate('/tradings/completed_add?isExchange=false', { replace: true })
        }
      } catch (error) {
        console.error('Error adding trading photo cards:', error)
      } finally {
        setIsUploading(false)
      }
    }
  }

  const handleTradeMethodSelect = (method) => {
    setSelectedTradeMethod(method);
    setIsDrawerOpen(false);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  return (
    <>
      <Helmet>
        <title>{t("ConfirmNewSalesTradingPhotoCardsPage.title")}</title> {/* Helmet을 사용하여 title 설정 */}
      </Helmet>
      <StickyHeader showBackButton={true} alwaysShowTitle={true}/>
      <PageWrapper>
        <PageRowContainer>
          <Title>{"판매할 포카"}</Title>
          {
            !isEmpty(tradingPhotoCards) &&
            <StickyContainer>
              <HorizontalScrollContainer>
                {
                  tradingPhotoCards?.map((tradingPhotoCard, index) => {
                    return (
                      <PriceContainer key={tradingPhotoCard.tradingPhotoCardId} index={index}>
                        <ImageWrapper>
                          <Image
                            src={tradingPhotoCard.photoCard.pictureUrl}
                            width={"40px"}
                            height={"63px"}
                            objectFit={"cover"}
                            loading={"lazy"}
                            borderRadius={"6px"}
                            marginRight={"0px!important"}
                          />
                        </ImageWrapper>
                        <PriceText>
                          {
                            isEmpty(tradingPhotoCard.price) ?
                            t("tradingPhotoCardPage.offerPriceWithEmoji") :
                            formatValue({ value: tradingPhotoCard.price, intlConfig: intlConfig(user) })
                          }
                        </PriceText>
                      </PriceContainer>
                    )
                  })
                }
              </HorizontalScrollContainer>
            </StickyContainer>
          }
        </PageRowContainer>

        <PageRowContainer>
          <Title>{"상세 내용"}</Title>
        </PageRowContainer>
        <div className="w-full">
          <ScrollMenu wrapperClassName='padding-left-scroll-container'>
            <AddImageButton onClick={handleAddImageClick}>
              <CameraIcon fill="black" />
              <span>{imageFiles.length}/10</span>
            </AddImageButton>
            {
              imageFiles.length > 0 && (
                imageFiles.map((file, index) => (
                  <ImageContainer key={index}>
                    <AttachedImage
                      src={file.content}
                      alt={`Attached Image ${index + 1}`}
                    />
                    <CloseButton
                      onClick={() => {
                        const newFiles = [...imageFiles];
                        newFiles.splice(index, 1);
                        setImageFiles(newFiles);
                      }}
                    >
                      <CloseIcon width={"16px"} height={"16px"} fill={"white"} />
                    </CloseButton>
                  </ImageContainer>
                ))
              )
            }
          </ScrollMenu>
        </div>
        <PageRowContainer style={{paddingTop: "16px"}}>
          <TextArea
            placeholder="여기에 내용을 입력하세요..."
            value={description}
            onChange={handleDescriptionChange}
          />
        </PageRowContainer>
        <PageRowContainer>
          <RadioRow
            isChecked={isNewItem}
            text={t("미개봉 상품 입니다.")}
            onChanged={() => setIsNewItem(!isNewItem)}
          />
        </PageRowContainer>

        <PageRowContainer className="flex justify-between items-center" style={{paddingTop: "24px"}} onClick={() => setIsDrawerOpen(true)}>
          <TradeMethodTitle>{"거래방식"}</TradeMethodTitle>
          <div className="flex flex-row gap-[4px]">
            <TradeMethodDescription>
              {t(`TradeMethod.${selectedTradeMethod || 'any'}`)}
            </TradeMethodDescription>
            <ArrowRightIcon fill={LightColors.textsublv1} width={"20px"} height={"20px"}/>
          </div>
        </PageRowContainer>
        {
          !isDrawerOpen && !isUploading &&
          <div
            className="bottomNavbar safeAreaPaddingBottom text-center"
            style={{ padding: "0px 24px 16px 24px", zIndex: 9999, background: LightColors.purewhite }}
          >
            {
              isUploading
                ? <Spinner size='sm' color="primary.500"/>
                : <PrimaryButton paddingY={"16px"} text={t("done")} zIndex={9999} onClick={onDone}/>
            }
          </div>
        }
      </PageWrapper>
      {
        isDrawerOpen &&
        <TradeMethodBottomDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onSelectTradeMethod={handleTradeMethodSelect}
        />
      }
    </>
  )
}

const RadioRow = ({isChecked, text, onChanged, props}) => {
  const { getInputProps } = useRadio(props);
  const input = getInputProps();

  return (
    <div className="flex flex-row" as="label">
      <input {...input} />
      <PriamryRadio text={text} isChecked={isChecked} onChanged={onChanged}/>
    </div>
  );
};
