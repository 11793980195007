import React, { useEffect, useMemo, useState } from "react";

import { Badge, Image, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Trans } from "react-i18next";
import StickyHeader from "../../components/StickyHeader";
import BumpTradingPhotoCard from "../../components/api/mutations/BumpTradingPhotoCard";
import updateWishedPhotoCard from "../../components/api/mutations/legacy/updateWishedPhotoCard";
import usePhotoCardQuery from "../../components/api/queries/usePhotoCardQuery";
import useReviewStatsQuery from "../../components/api/queries/useReviewStatsQuery";
import useTradingPhotoCardQuery from "../../components/api/queries/useTradingPhotoCardQuery";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullSizeImageViewer from "../../components/chats/ImageViewer";
import { TagsList } from "../../components/photocard/TagsList";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import LightColors from "../../constants/LightColors";
import { setEvent } from "../../utils/ga";


const Background = styled.div`
  background: url(/assets/backgrounds/bg.gray.gradient.svg);
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: calc(128px + env(safe-area-inset-bottom));
`

const NavigationWrapper = styled.div`
  flex-direction: row;
  display: flex;
`

const TradingPhotoCardImageWrapper = styled.div`
  padding-top: calc(64px + env(safe-area-inset-top));
`

const PhotoCardSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 24px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: baseline;
`;

const PhotoCardName = styled.div`
  color: ${LightColors.textmainlv2};
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  white-space: nowrap;
`

const EventName = styled.div`
  color: ${LightColors.textmainlv3};
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: left;
  margin-top: 8px!important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const PriceText = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: ${LightColors.primary};
`

const RowContainer = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 4px;
  flex-wrap: wrap;
`

export default function TradingPhotoCardDetailPage() {
  const params = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const [photoCard, setPhotoCard] = useState();
  const [selectedPictureUrl, setSelectedPictureUrl] = useState(null);
  const tradingPhotoCard = useTradingPhotoCardQuery(params.trading_photo_card_id);

  const reviewStats = useReviewStatsQuery({userProfileId: tradingPhotoCard?.userProfileId});


  const photoCardData = usePhotoCardQuery(currentQueryParams.get("photoCardId"));

  useEffect(() => {
    if (!isEmpty(photoCardData)) {
      setPhotoCard(photoCardData);
    }
  }, [photoCardData]);

  const toggleWishedState = async (isWished, card) => {
    setEvent({
      category: 'PhotoCard',
      action: isWished ? 'UnWished PhotoCard' : 'Wished PhotoCard',
    })
    const response = await updateWishedPhotoCard(isWished, card.artistId, card.memberId, [card.photoCardId]);
    if(response.success) {
      invalidateQueries(card);
      setPhotoCard(prevData => ({
        ...prevData,
        isWished: !isWished,
      }));
    }
  }

  const invalidateQueries = (photoCard) => {
    queryClient.invalidateQueries(["photoCardV1", photoCard.photoCardId]);
    queryClient.invalidateQueries(["photoCardEvents", photoCard.artistId, photoCard.memberId]);
  }

  const navigatePhotoCardReport = (photoCardId) => {
    navigate(`/report/photo-card?photoCardId=${photoCardId}`)
  }

  const onRequestBump = async () => {
    const response = await BumpTradingPhotoCard(tradingPhotoCard?.tradingPhotoCardId)
    if (response.ok) {
      alert('끌올이 완료되었습니다.')
    } else {
      alert('끌올에 실패했습니다.')
    }
  }

  const ImageUrls = useMemo(() => {
    if (isEmpty(tradingPhotoCard?.pictureUrls)) {
      return [photoCard?.pictureUrl]
    } else {
      return [...tradingPhotoCard?.largeThumbnailUrls, photoCard?.pictureUrl]
    }
  }, [tradingPhotoCard?.pictureUrls, tradingPhotoCard?.largeThumbnailUrls, photoCard?.pictureUrl]);

  if(isEmpty(photoCard) || isEmpty(tradingPhotoCard)) return <></>

  return (
    <Background>
      <StickyHeader
        transparent={true}
        showBackButton={true}
      />
        <TradingPhotoCardImageWrapper>
          {
            tradingPhotoCard &&
              <div className="relative hiddenScrollBar">
                <div className="flex overflow-x-auto snap-x snap-mandatory">
                  {ImageUrls?.map((pictureUrl) => (
                    <div key={pictureUrl} className="flex-none w-full snap-center">
                      <Image
                        width={"100%"}
                        height={"375px"}
                        objectFit={"cover"}
                        src={pictureUrl}
                        onClick={() => setSelectedPictureUrl(pictureUrl)}
                      />
                    </div>
                  ))}
                </div>
                <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-2">
                  {ImageUrls?.map((_, index) => (
                    <div
                      key={index}
                      className="w-2 h-2 rounded-full bg-white opacity-50"
                    />
                  ))}
                </div>
              </div>
          }
        </TradingPhotoCardImageWrapper>
        <PhotoCardSection>
          {
            isEmpty(photoCard) ? (
              <div className="w-full">
                <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={128}/>
              </div>
            ) : (
              <VStack align={"start"}>
              <Row>
                <PhotoCardName>
                  {t("photoCardDetailPage.title", {memberName: photoCard.memberName, categoryName: photoCard.categoryName, categoryEmoji: photoCard.categoryEmoji})}
                </PhotoCardName>
                <EventName>
                  {photoCard.eventName}
                </EventName>
              </Row>
              <div className="flex justify-between w-full">
                <PriceText>{tradingPhotoCard.price}</PriceText>
                <div className="flex items-center gap-2">
                  {
                    tradingPhotoCard.tradeMethod &&
                    <Badge px={"8px"} py={"4px"}>{t(`TradeMethod.${tradingPhotoCard.tradeMethod}`)}</Badge>
                  }

                  <Badge px={"8px"} py={"4px"}>{tradingPhotoCard.isNewItem ? t('new') : t('used')}</Badge>
                </div>
              </div>
              </VStack>
            )
          }
        </PhotoCardSection>
        {
          photoCard &&
          <RowContainer>
            <TagsList photoCard={photoCard}/>
          </RowContainer>
        }
        {
        <RowContainer>
          <Image src={tradingPhotoCard?.userProfile?.pictureUrl} alt="판매자 사진" className="w-10 h-10 rounded-full" />
          <VStack align="start" gap={"5px"}>
            <div>
              <span className="text-xs text-gray-500">{tradingPhotoCard?.userProfile?.nickname}</span>
              {
                tradingPhotoCard?.userProfile?.responseText &&
                <span className="text-xs text-gray-500">{` • 응답률: ${tradingPhotoCard?.userProfile?.responseText}`}</span>
              }
            </div>
            {
              reviewStats &&
              ScoreTitle({
                reviewsCount: reviewStats.reviewsCount,
                positiveFeedbackOptions: reviewStats.positiveFeedbackOptions
              })
            }
          </VStack>
        </RowContainer>
        }
        <RowContainer>
          <span>{tradingPhotoCard.description}</span>
          <span className="ml-auto">
            {tradingPhotoCard.requestedAt}
            {tradingPhotoCard.bumpedAt && ` (끌올: ${tradingPhotoCard.bumpedAt})`}
          </span>
        </RowContainer>
        <div
          className="bottomNavbar safeAreaPaddingBottom text-center"
          style={{ padding: "0px 24px 16px 24px", zIndex: 9999, background: LightColors.purewhite }}
        >
          {
            tradingPhotoCard?.isMine &&
            <PrimaryButton paddingY={"16px"} text={"끌올하기"} zIndex={9999} onClick={onRequestBump}/>
          }
        </div>
      {
        selectedPictureUrl && <FullSizeImageViewer imageUrl={selectedPictureUrl} onClosed={() => setSelectedPictureUrl(null)}/>
      }
    </Background>
  )
}


const StyledScoreTitle = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${LightColors.textsublv1};
  margin-top: 0px!important;
`;

const ColorText = styled.span`
  color: ${(props) => props.reviewColor};
`;

const ReviewScoreColor = (score) => {
  return score <= 30 ? "#FF4C41" : (score <= 60 ? "#FAD722" : "#2DDD28")
}

const ColoreText = (score) => {
  return score <= 30 ? t("ReviewColorText.red") : (score <= 60 ? t("ReviewColorText.yellow") : t("ReviewColorText.green"))
}


const ScoreTitle = ({ score }) => {
  const reviewColor = ReviewScoreColor(score);
  const colorText = ColoreText(score);
  const onlyColorText = score <= 10 || (score >= 40 && score <= 60) || (score >= 80 && score <= 100);

  return (
    <StyledScoreTitle>
      {
        onlyColorText ?
        <ColorText reviewColor={reviewColor}>{colorText}</ColorText> :
        <Trans
          i18nKey="TradingUserProfilePage.reviewColorText"
          values={{ color: colorText }}
          components={{
            color: <ColorText reviewColor={reviewColor} />
          }}
        />
      }
    </StyledScoreTitle>
  );
};
