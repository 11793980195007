import { useInfiniteQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import { isEmpty, isNull } from "lodash";

const DEFAULT_PAGE = 30

export default function useExploreTradingPhotoCardsQueryV3({
  artistId,
  memberIds,
  eventIds,
  category,
  userProfileId,
  onlyIdentityVerified,
  photoCardCategories,
  sortBy="scoring",
  perPage=DEFAULT_PAGE,
  }) {
  async function fetchExploreTradingPhotoCards({ pageParam = 1 }) {
    const queryParams = new URLSearchParams({
      per_page: perPage,
      page: pageParam,
      sort_by: sortBy,
    });

    if (!isEmpty(artistId)) {
      queryParams.append("artist_id", artistId)
    }

    if (!isEmpty(memberIds)) {
      queryParams.append("member_ids", memberIds)
    }

    if (!isEmpty(eventIds)) {
      queryParams.append("event_ids", eventIds)
    }

    if (onlyIdentityVerified) {
      queryParams.append("identified", onlyIdentityVerified)
    }

    if(userProfileId) {
      queryParams.append("user_profile_id", userProfileId)
    }

    if(category) {
      queryParams.append("category", category)
    }

    if(!isEmpty(photoCardCategories)) {
      queryParams.append("photo_card_categories", photoCardCategories)
    }

    const url = `/api/v2/trading_photo_cards?${queryParams.toString()}`;
    try {
      const data = await queryGet(url);
      return data;
    } catch (error) {
      throw new Error('Error fetching paginated data.');
    }
  }

  const {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["ExploreTradingPhotoCardsV3", artistId, memberIds, eventIds, category, userProfileId, onlyIdentityVerified, photoCardCategories, sortBy],
    fetchExploreTradingPhotoCards,
    {
      enabled: !!artistId && !isNull(memberIds),
      getNextPageParam: (lastPage) => {
        if (!!lastPage?.meta?.pagination?.nextPage) {
          return lastPage?.meta?.pagination?.nextPage;
        } else {
          return undefined
        }
      }
    }
  );

  return {
    data,
    isFetching,
    fetchNextPage,
    hasNextPage,
  };
}
