import { Divider, Image } from '@chakra-ui/react';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from "react";
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';
import LightColors from '../../constants/LightColors';
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { intlConfig } from '../../utils/CurrencyUtil';
import { TagsList } from "../photocard/TagsList";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${LightColors.separatorlv1};
`

const PhotoCardName = styled.div`
  color: ${LightColors.textmainlv2};
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const EventName = styled.div`
  color: ${LightColors.textmainlv3};
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: left;
  margin-top: 8px!important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 4px;
  flex-wrap: wrap;
  margin-top: 8px !important;
`

const closeIconStyle = {
  width: "28px",
  height: "28px",
  backgroundColor: "rgba(92, 61, 250, 0.06)",
  borderRadius: "32px",
  padding: "6px",
  marginBottom: "16px",
}

 // Start of Selection
const inputStyle = {
  width: "100%",
  fontSize: "17px",
  fontWeight: 500,
  lineHeight: "32px",
  color: LightColors.textmainlv1,
  textAlign: "right",
  borderWidth: 0,
  borderColor: "transparent",
  outline: "none",
  '&:focus': {
    borderColor: "transparent"
  },
  '&:placeholder': {
    textAlign: "left",
    fontSize: "17px",
    lineHeight: "32px",
    fontWeight: 500,
    color: LightColors.primary,
  }
}

export default function NewSaleTradingPhotoCard({user, tradingPhotoCard, onChangedItem, onDeletedItem, confirmed=false, hideToggle=false}) {
  const [price, setPrice] = useState(tradingPhotoCard.price);
  const [checked, setChecked] = useState(tradingPhotoCard?.category === "sale");

  useEffect(() => {
    setChecked(tradingPhotoCard?.category === "sale")
  }, [tradingPhotoCard])

  const updatedPrice = (value) => {
    setPrice(value);
    updateTradingPhotoCard(checked, value);
  }

  const updateTradingPhotoCard = (checked, price) => {
    onChangedItem({
      ...tradingPhotoCard,
      price: price,
      category: checked ? 'sale' : 'exchange',
    })
  };

  return (tradingPhotoCard &&
    <>
      <Box key={tradingPhotoCard.tradingPhotoCardId}>
        <div className="flex flex-row gap-0">
          <Image
            width={"48px"}
            height={"75px"}
            borderRadius={"8px"}
            aspectRatio={"55/87"}
            src={tradingPhotoCard.photoCard.pictureUrl}
            objectFit={"cover"}
            loading={"lazy"}
          />
          <div className='flex flex-col flex-grow pl-[0.875rem] self-center'>
            <PhotoCardName>
              {t("photoCardDetailPage.title", {memberName: tradingPhotoCard.photoCard.memberName, categoryName: tradingPhotoCard.photoCard.categoryName, categoryEmoji: tradingPhotoCard.photoCard.categoryEmoji})}
            </PhotoCardName>
            <EventName>{tradingPhotoCard.photoCard.eventName}</EventName>
            {
              !isEmpty(tradingPhotoCard.photoCard.tags) && !isEmpty(tradingPhotoCard.photoCard.distributors) &&
              <TagsWrapper>
                <TagsList photoCard={tradingPhotoCard.photoCard}/>
              </TagsWrapper>
            }
          </div>
          <div className="flex-grow flex justify-end -m-1.5">
            <CloseIcon fill={LightColors.primary} style={closeIconStyle} onClick={() => onDeletedItem(tradingPhotoCard)}/>
          </div>
        </div>
        <Divider paddingTop={"14px"} paddingBottom={"12px"} width={"100%"} zIndex={999}/>
        <CurrencyInput
          style={inputStyle}
          disabled={confirmed}
          intlConfig={intlConfig(user)}
          allowNegativeValue={false}
          placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
          value={price}
          thousandSeparator={true}
          onValueChange={(e) => updatedPrice(e)}
        />
      </Box>
    </>
  )
}

