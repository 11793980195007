import React from "react"

import { Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerOverlay, Image } from "@chakra-ui/react"
import { useQueryClient } from "react-query"
import styled from "styled-components"

import LightColors from "../../constants/LightColors"
import { openNewTab } from "../../utils/UIUtils"
import DisabledLineButton from "../buttons/DisabledLineButton"
import CancelMegaphone from "../api/mutations/CancelMegaphone"
import Megaphone from "../api/mutations/Megaphone"
import { t } from "i18next"

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CanceledText = styled.div`
  color: red;
  font-size: 17px;
  font-weight: 600;
  line-height: 20.4px;
  text-align: left;
  padding-left: 10px;
`

const NormalText = styled.div`
  color: ${LightColors.textmainlv1};
  font-size: 17px;
  font-weight: 600;
  line-height: 20.4px;
  text-align: left;
  padding-left: 10px;
`

const MEGAPHONE_MENU = {
  key: "megaphone",
  imagePath: "/assets/icons/repost_icon.png",
}

const UNMEGAPHONE_MENU = {
  key: "unmegaphone",
  imagePath: "/assets/icons/repost_icon.png",
}

const QUOTES_MENU = {
  key: "quotes",
  imagePath: "/assets/icons/quotes_icon.png",
}

export default function RepostBottomDrawer({isOpen, onClose, focusRef, post, onConfirmedRepost, onCanceledRepost}) {
  const queryClient = useQueryClient();
  const onSelectedMenu = async (menu) => {
    onClose();
    if(menu?.key === "megaphone") {
      onConfirmedRepost && onConfirmedRepost();
      const response = await Megaphone(post.postId);
      if(response && response.ok) {
        queryClient.invalidateQueries("Posts");
      }
    } else if (menu?.key === "unmegaphone") {
      onCanceledRepost && onCanceledRepost();
      const response = await CancelMegaphone(post.postId);
      if(response && response.ok) {
        queryClient.invalidateQueries("Posts");
      }
    } else if (menu?.key === "quotes") {
      openNewTab(`/posts/add?quotedPostId=${post.postId}`);
    }
  };

  const MenuText = (key) => {
    if (key === "megaphone") {
      return t("reportBottomSheet.megaphone");
    } else if (key === "unmegaphone") {
      return t("reportBottomSheet.unmegaphone");
    } else {
      return t("reportBottomSheet.quotes")
    }
  }

  const MenuRow = (menu) => {
    return (
      <Row key={menu.key} onClick={() => onSelectedMenu(menu)}>
        <Image width="22px" height="22px" src={menu.imagePath}/>
        {
          menu.key === UNMEGAPHONE_MENU.key ?
          <CanceledText>{MenuText(menu.key)}</CanceledText> :
          <NormalText>{MenuText(menu.key)}</NormalText>
        }
      </Row>
    )
  }



  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement='bottom'
        finalFocusRef={focusRef}
      >
        <DrawerOverlay/>
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                y: "0%",
                transition: { duration: 0.15 },
              },
              exit: {
                y: "100%",
                transition: { duration: 0.1 },
              },
            }
          }}
          style={{
            maxWidth: "calc(var(--chakra-sizes-xl))",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            left: "",
            right: ""
          }}
        >
          <DrawerBody paddingTop={"20px"} paddingX={"20px"}>
            <RowWrapper>
              {
                post.isMegaphoned ? MenuRow(UNMEGAPHONE_MENU) : MenuRow(MEGAPHONE_MENU)
              }
              {
                MenuRow(QUOTES_MENU)
              }
            </RowWrapper>
          </DrawerBody>
          <DrawerFooter width={"100%"} paddingTop={"10px"} paddingBottom={"30px"} paddingX={"20px"}>
            <DisabledLineButton
              width={"100%"}
              text={t("cancel")}
              paddingY={"16px"}
              onClick={() => onClose()}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
