import React, { useState } from "react";

import { Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerOverlay, Radio } from "@chakra-ui/react";

import styled from "styled-components";
import LightColors from "../../constants/LightColors";
import SupportedLocales from "../../constants/SupportedLocales";
import { t } from "i18next";

const DrawerTitleRow = styled.div`
  padding-top: 24px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 12px;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${LightColors.textmainlv3};
`

const Row = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: row;
`

const TextWrapper = styled.div`
  flex-grow: 1;
`
const SelectedText = styled.div`
  color: ${LightColors.primary};
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`

const UnSelectedText = styled.div`
  color: ${LightColors.textMain};
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`

const DisabledText = styled.div`
  color: ${LightColors.disable};
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`

const RadioWrapper = styled.div`
  align-content: center;
  flex-shrink: 1;
`
export default function SelectLocaleBottomDrawer({isOpen, onClose, focusRef, defaultLocale, onConfirmedLocale}) {
  const [selectedLocale, setSelectedLocale] = useState(defaultLocale);

  const onSelectLocale = (locale) => {
    setSelectedLocale(locale);
    onConfirmedLocale(locale);
    onClose();
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement='bottom'
        finalFocusRef={focusRef}
      >
        <DrawerOverlay/>
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                y: "0%",
                transition: { duration: 0.15 },
              },
              exit: {
                y: "100%",
                transition: { duration: 0.1 },
              },
            }
          }}
          style={{
            maxWidth: "calc(var(--chakra-sizes-xl))",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            left: "",
            right: ""
          }}
        >
          <DrawerTitleRow>
            {t("changeCountry.localeDrawerFirstRow")}
          </DrawerTitleRow>
          <DrawerBody paddingX={"20px"}>
            {
              SupportedLocales.map((locale) => {
                return(
                  <Row key={locale.code} onClick={() => onSelectLocale(locale.code)}>
                  <TextWrapper>
                    {
                      locale.code === selectedLocale ?
                      <SelectedText>
                        {locale.name}
                      </SelectedText> :
                      <UnSelectedText>
                        {locale.name}
                      </UnSelectedText>
                    }
                  </TextWrapper>
                  <RadioWrapper>
                    <Radio colorScheme="primary" isChecked={locale.code === selectedLocale}/>
                  </RadioWrapper>
                </Row>
                )
              })
            }
            <Row>
              <DisabledText>
                {t("changeCountry.localeDrawerLastRow")}
              </DisabledText>
            </Row>
          </DrawerBody>
          <DrawerFooter className="safeAreaMarginBottom" width={"100%"} paddingTop={"10px"} paddingBottom={"0px"} paddingX={"20px"}>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
