import { Image, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty, isUndefined } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";

import StickyHeader from "../../components/StickyHeader";
import BumpTradingPhotoCard from "../../components/api/mutations/BumpTradingPhotoCard";
import createChatRoom from "../../components/api/mutations/legacy/createChatRoom";
import updateWishedPhotoCard from "../../components/api/mutations/legacy/updateWishedPhotoCard";
import usePhotoCardQuery from "../../components/api/queries/usePhotoCardQuery";
import useReviewStatsQuery from "../../components/api/queries/useReviewStatsQuery";
import useTradingPhotoCardQuery from "../../components/api/queries/useTradingPhotoCardQuery";
import { useTradingPhotoCardsByProfileId } from "../../components/api/queries/useTradingPhotoCards";
import LikeButton from "../../components/buttons/LikeButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FullSizeImageViewer from "../../components/chats/ImageViewer";
import VerifiedIcon from "../../components/icons/VerifiedIcon";
import { TagsList } from "../../components/photocard/TagsList";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import ProfileAvatar from "../../components/thumbnails/ProfileAvatar";
import CompactTradingPhotoCard from "../../components/tradings/CompactTradingPhotoCard";
import LightColors from "../../constants/LightColors";
import { setEvent } from "../../utils/ga";


const Background = styled.div`
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: calc(128px + env(safe-area-inset-bottom));
`

const TradingPhotoCardImageWrapper = styled.div`
  padding-top: calc(64px + env(safe-area-inset-top));
`

const PhotoCardSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  padding-bottom: 20px;
  margin-left: 24px;
  margin-right: 24px;
  border-bottom: 1px solid ${LightColors.separatorlv1};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: baseline;
`;

const PhotoCardName = styled.div`
  color: ${LightColors.textmainlv1};
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  white-space: nowrap;
`

const DotText = styled.div`
  color: ${LightColors.separatorlv2};
`

const EventName = styled.div`
  color: ${LightColors.textmainlv3};
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const PriceText = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: ${LightColors.primary};
`

const DeliveryBadge = styled.div`
  color: ${LightColors.textprimary};
  background: ${LightColors.surface};
  border-bottom: 1px solid ${LightColors.separatorlv1};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`

const TagContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 24px;
  gap: 24px;
  border-bottom: 1px solid ${LightColors.separatorlv1};
`

const Description = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: ${LightColors.textmainlv1};
`

const DateTimeText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${LightColors.textsublv1};
`

const SellerTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  padding: 16px 24px;
  color: ${LightColors.textmainlv2};
`

const SellerContainer = styled.div`
  padding: 0px 24px 16px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`

const NickName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: ${LightColors.textmainlv3};
`

const ResponseRate = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  color: ${LightColors.textprimary};
`


export default function TradingPhotoCardDetailPage() {
  const params = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const [photoCard, setPhotoCard] = useState();
  const [selectedPictureUrl, setSelectedPictureUrl] = useState(null);
  const tradingPhotoCard = useTradingPhotoCardQuery(params.trading_photo_card_id);

  const reviewStats = useReviewStatsQuery({userProfileId: tradingPhotoCard?.userProfileId});
  const photoCardData = usePhotoCardQuery(currentQueryParams.get("photoCardId"));

  const sellerTradingPhotoCards = useTradingPhotoCardsByProfileId({
    userProfileId: tradingPhotoCard?.userProfileId,
    category: "sale",
  });

  useEffect(() => {
    if (!isEmpty(photoCardData)) {
      setPhotoCard(photoCardData);
    }
  }, [photoCardData]);

  const toggleWishedState = async (isWished, card) => {
    setEvent({
      category: 'PhotoCard',
      action: isWished ? 'UnWished PhotoCard' : 'Wished PhotoCard',
    })
    const response = await updateWishedPhotoCard(isWished, card.artistId, card.memberId, [card.photoCardId]);
    if(response.success) {
      invalidateQueries(card);
      setPhotoCard(prevData => ({
        ...prevData,
        isWished: !isWished,
      }));
    }
  }

  const invalidateQueries = (photoCard) => {
    queryClient.invalidateQueries(["photoCardV1", photoCard.photoCardId]);
    queryClient.invalidateQueries(["photoCardEvents", photoCard.artistId, photoCard.memberId]);
  }

  const onRequestBump = async () => {
    const response = await BumpTradingPhotoCard(tradingPhotoCard?.tradingPhotoCardId)
    if (response.ok) {
      //FIXME use Toast
      alert('게시글을 끌어올렸습니다.')
    } else {
      alert('끌올에 실패했습니다.')
    }
  }

  const navigateSellerProfile = () => {
    navigate(`/trading/user_profile/${tradingPhotoCard?.userProfileId}`)
  }

  const navigateChatRoom = async () => {
    const response = await createChatRoom(tradingPhotoCard.artistId, tradingPhotoCard.tradingPhotoCardId);
    if (response.success) {
      navigate(`/artists/${tradingPhotoCard.artistId}/chats/${response.chatRoom.chatRoomId}`)
    }
  }

  const ImageUrls = useMemo(() => {
    if (isEmpty(tradingPhotoCard?.pictureUrls)) {
      return [photoCard?.pictureUrl]
    } else {
      return [...tradingPhotoCard?.largeThumbnailUrls, photoCard?.pictureUrl]
    }
  }, [tradingPhotoCard?.pictureUrls, tradingPhotoCard?.largeThumbnailUrls, photoCard?.pictureUrl]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_, next) => setCurrentIndex(next),
  };

  if(isEmpty(photoCard) || isEmpty(tradingPhotoCard)) return <></>

  return (
    <Background>
      <StickyHeader
        transparent={true}
        showBackButton={true}
      />
        <TradingPhotoCardImageWrapper>
          {
            tradingPhotoCard &&
              <div className="relative">
                <Slider {...settings}>
                  {ImageUrls?.map((pictureUrl, index) => (
                    <div key={pictureUrl} className="w-full">
                      {index === ImageUrls.length - 1 ? (
                        <div className="w-full h-[375px] flex items-center justify-center" style={{background: LightColors.surface}}>
                          <Image
                            width={"122px"}
                            height={"182px"}
                            objectFit={"contain"}
                            borderRadius={"16px"}
                            src={pictureUrl}
                            onClick={() => setSelectedPictureUrl(pictureUrl)}
                            boxShadow={"0px 0px 15px 0px #0000001A"}
                          />
                        </div>
                      ) : (
                        <Image
                          width={"100%"}
                          height={"375px"}
                          objectFit={"cover"}
                          borderRadius={"8px"}
                          src={pictureUrl}
                          onClick={() => setSelectedPictureUrl(pictureUrl)}
                        />
                      )}
                    </div>
                  ))}
                </Slider>
                <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-2">
                  {ImageUrls?.map((_, index) => (
                    <div
                      key={index}
                      className={"w-2 h-2 rounded-full"}
                      style={{ background: index === currentIndex ? LightColors.primary : LightColors.placeholder }}
                    />
                  ))}
                </div>
              </div>
          }
        </TradingPhotoCardImageWrapper>
        <PhotoCardSection>
          {
            isEmpty(photoCard) ? (
              <div className="w-full">
                <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={128}/>
              </div>
            ) : (
              <VStack align={"start"} spacing={"16px"} width={"100%"}>
              <Row>
                <PhotoCardName>
                  {t("photoCardDetailPage.title", {memberName: photoCard.memberName, categoryName: photoCard.categoryName, categoryEmoji: photoCard.categoryEmoji})}
                </PhotoCardName>
                <DotText>{"•"}</DotText>
                <EventName>
                  {photoCard.eventName}
                </EventName>
              </Row>
              <div className="flex justify-between w-full mt-0">
                <PriceText>{tradingPhotoCard.price}</PriceText>
                <div className="flex items-center gap-2">
                  {
                    tradingPhotoCard.tradeMethod &&
                    <DeliveryBadge px={"8px"} py={"4px"}>{t(`TradeMethod.${tradingPhotoCard.tradeMethod}`)}</DeliveryBadge>
                  }
                </div>
              </div>
              </VStack>
            )
          }
        </PhotoCardSection>
        {
          (photoCard && !isEmpty(photoCard.distributors) && !isEmpty(photoCard.tags)) &&
          <TagContainer>
            <TagsList photoCard={photoCard} size={"md"}/>
          </TagContainer>
        }
        <DescriptionWrapper>
          <Description>{tradingPhotoCard.description}</Description>
          <div className="flex flex-row ml-auto" style={{paddingBottom: "16px", gap: "4px"}}>
            <DateTimeText>{tradingPhotoCard.requestedAt}</DateTimeText>
            {
              tradingPhotoCard.bumpedAtText &&
              <>
                <DateTimeText>{"•"}</DateTimeText>
                <DateTimeText>{`끌올 ${tradingPhotoCard.bumpedAtText}`}</DateTimeText>
              </>
            }
          </div>
        </DescriptionWrapper>
        <SellerTitle>{"판매자정보"}</SellerTitle>

        <SellerContainer onClick={navigateSellerProfile}>
          <ProfileAvatar profileImageUrl={tradingPhotoCard?.userProfile?.pictureUrl} widthAndHeight={"43px"} svgWidthAndHeight={"32px"} />
          <VStack align="start" gap={"5px"} alignSelf={"center"}>
            <div className="flex flex-row items-center gap=[3px]">
              <NickName>{tradingPhotoCard?.userProfile?.nickname}</NickName>
              {
                tradingPhotoCard?.userProfile?.identified && <VerifiedIcon width={"13px"} height={"13px"} />
              }
              {
                tradingPhotoCard?.userProfile?.responseText &&
                <>
                  <DotText style={{lineHeight: "17px"}}>{"•"}</DotText>
                  <ResponseRate>{`${t("TradingUserProfilePage.statResponseRate")} ${tradingPhotoCard?.userProfile?.responseText}`}</ResponseRate>
                </>
              }
            </div>
            {
              reviewStats &&
              ScoreTitle({
                reviewsCount: reviewStats.reviewsCount,
                positiveFeedbackOptions: reviewStats.positiveFeedbackOptions
              })
            }
          </VStack>
        </SellerContainer>

        <div>
          {
            sellerTradingPhotoCards && <TradingPhotoCardsList tradingPhotoCards={sellerTradingPhotoCards}/>
          }
        </div>

        <div
          className="bottomNavbar safeAreaPaddingBottom flex items-center gap-4 w-full"
          style={{ padding: "12px 24px", zIndex: 9999, background: LightColors.purewhite }}
        >
          <LikeButton
            width="52px"
            height="52px"
            onClick={() => console.log("Like clicked")}
          />
          {tradingPhotoCard?.isMine ? (
            tradingPhotoCard.bumpRemainingTime ?
            <PrimaryButton
              width={"100%"}
              py={"16px"}
              disabled={true}
              text={tradingPhotoCard.bumpRemainingTime}
              borderRadius={"8px"}
              style={{
                fontSize: "17px",
                fontWeight: 600,
                lineHeight: "20px"
              }}
            /> :
            <PrimaryButton
              width={"100%"}
              py={"16px"}
              text={"끌어올리기"}
              borderRadius={"8px"}
              style={{
                fontSize: "17px",
                fontWeight: 600,
                lineHeight: "20px"
              }}
              onClick={onRequestBump}
            />

          ) : (
            <PrimaryButton
              width={"100%"}
              text={t("sendMessage")}
              py={"16px"}
              borderRadius={"8px"}
              style={{
                fontSize: "17px",
                fontWeight: 600,
                lineHeight: "20px"
              }}
              onClick={() => navigateChatRoom()}
            />
          )}
        </div>
      {
        selectedPictureUrl && <FullSizeImageViewer imageUrl={selectedPictureUrl} onClosed={() => setSelectedPictureUrl(null)}/>
      }
    </Background>
  )
}


const StyledScoreTitle = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${LightColors.textsublv1};
  margin-top: 0px!important;
`;

const ColorText = styled.span`
  color: ${(props) => props.reviewColor};
  font-weight: 600;
`;

const ReviewScoreColor = (score) => {
  return score <= 30 ? "#FF4C41" : (score <= 60 ? "#FAD722" : "#2DDD28")
}

const ColoreText = (score) => {
  return score <= 30 ? t("ReviewColorText.red") : (score <= 60 ? t("ReviewColorText.yellow") : t("ReviewColorText.green"))
}

const MannerSignalImage = (score) => {
  if (score <= 30) {
    return "/assets/icons/manner-signals/manner.signal.red.png";
  } else if (score <= 60) {
    return "/assets/icons/manner-signals/manner.signal.yellow.png";
  } else {
    return "/assets/icons/manner-signals/manner.signal.green.png";
  }
};


const ScoreTitle = ({ score }) => {
  const reviewColor = ReviewScoreColor(score);
  const colorText = ColoreText(score);

  return (
    <StyledScoreTitle>
      <div className="flex flex-row">
        <div>{t("TradingUserProfilePage.mannerSignal")}</div>
        <Image width={"15px"} height={"15px"} marginLeft={"4px"} marginRight={"2px"} src={MannerSignalImage(score)}/>
        <Trans
          i18nKey="TradingUserProfilePage.reviewColorText"
          values={{ color: colorText }}
          components={{
            color: <ColorText reviewColor={reviewColor} />
          }}
        />
      </div>
    </StyledScoreTitle>
  );
};


const TradingPhotoCardsList = ({tradingPhotoCards}) => {
  return (
    <>
      {
        isUndefined(tradingPhotoCards) ?
          <GridSkeletons gridSize={3} skeletonSize={9} skeletonHeight={'109px'} gap={"6px"}/> :
          <div style={{
            display: 'flex',
            overflowX: 'auto',
            padding: '0 24px 16px 24px',
            gap: '8px',
            scrollbarWidth: 'none', // For Firefox
            msOverflowStyle: 'none', // For IE and Edge
            '&::-webkit-scrollbar': { // For Chrome, Safari and Opera
              display: 'none'
            }
          }}>
            {
              tradingPhotoCards?.map((tradingPhotoCard) => (
                <div key={tradingPhotoCard.tradingPhotoCardId}>
                  <CompactTradingPhotoCard
                    tradingPhotoCard={tradingPhotoCard}
                    hiddenNickname={true}
                    hiddenChatStat={true}
                    showEventName={true}
                  />
                </div>
              ))
            }
          </div>
      }
    </>
  )
}
