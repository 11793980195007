import styled from "styled-components";
import { ReactComponent as LikeLineIcon } from "../../icons/like.line.svg";
import LightColors from "../../constants/LightColors";

const StyledButton = styled.button`
  min-width: 52px;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  background-color: ${LightColors.secondary};
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${LightColors.secondaryHover}; // 호버 시 색상 변경 (필요 시 추가)
  }
`;

const LikeButton = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <LikeLineIcon width={"24px"} height={"24px"} fill={LightColors.primary}/>
    </StyledButton>
  );
};

export default LikeButton;
