import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Text, useToast } from '@chakra-ui/react';
import { useQueryClient } from "react-query";
import { t } from "i18next";

import PrimaryButton from "../buttons/PrimaryButton";
import { ReactComponent as LikeLineIcon } from "../../icons/like.line.svg";
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";
import baseUrl from "../../utils/baseUrl";
import getToken from "../../utils/getToken";
import MatchedTradingPhotoCardInfo from "../tradings/MatchedTradingPhotoCardInfo";

const headerStyle = {
  paddingTop: "24px",
  paddingBottom: "0px",
}

const titleStyle = {
  fontSize: "17px",
  fontWeight: 700,
}

const buttonStyle = {
  padding: "16px 24px"
}

const likeBoxStyle = {
  width: "52px",
  height: "52px",
  padding: "16px 14px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "52px",
  backgroundColor: "rgba(92, 61, 250, 0.06)"
}

const wishedIconStyle = {
  width: "24px",
  height: "24px"
}

async function getChatRoom(matchedTradingPhotoCardId) {
  return fetch(baseUrl() + `/matched_trading_photo_cards/${matchedTradingPhotoCardId}/chat_room`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then((data) => data.json())
}

async function toggleWished(matchedTradingPhotoCardId, wished) {
  const url = `/matched_trading_photo_cards/${matchedTradingPhotoCardId}/${wished ? "unwished" : "wished"}`;
  return fetch(baseUrl() + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then((data) => data.json())
}

async function addBlacklistCard(matchedTradingPhotoCardId) {
  return fetch(baseUrl() + `/matched_trading_photo_cards/${matchedTradingPhotoCardId}/blacklisted`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
  }).then((data) => data.json())
}

export default function MatchedTradingPhotoCardBottomDrawer({ isOpen, onClose, focusRef, artistId, matchedTradingPhotoCard }) {
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [wished, setWished] = useState(matchedTradingPhotoCard.isWished);
  const [enableBackdrop, setEnableBackdrop] = useState(false);

  // Defence code to close immediately Bottomsheet
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => { setEnableBackdrop(true) }, 300);
    } else {
      setEnableBackdrop(false);
    }
  }, [isOpen]);

  const navigateChatRoom = () => {
    getChatRoom(matchedTradingPhotoCard.matchedTradingPhotoCardId).then((r) => {
      if (r.success) {
        navigate(`/artists/${artistId || params["artist_id"]}/chats/${r.chatRoom.chatRoomId}`)
      }
    })
  }

  const onClickWished = (matchedTradingPhotoCardId) => {
    toggleWished(matchedTradingPhotoCardId, wished);
    setWished(!wished);
  }

  const onClickBlacklisted = (matchedTradingPhotoCardId) => {
    addBlacklistCard(matchedTradingPhotoCardId);
    queryClient.invalidateQueries("exploreTradingPhotoCards");
    queryClient.invalidateQueries("matchedTradingPhotoCards");
    setTimeout(() => { onClose() }, 300);
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement='bottom'
      finalFocusRef={focusRef}
    >
      <DrawerOverlay />
      <DrawerContent
        motionProps={{
          variants: {
            enter: {
              y: "0%",
              transition: { duration: 0.15 },
            },
            exit: {
              y: "100%",
              transition: { duration: 0.1 },
            },
          }
        }}
        style={{
          maxWidth: "calc(var(--chakra-sizes-xl))",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          left: "",
          right: ""
        }}
      >
        <DrawerHeader style={headerStyle}>
          <SimpleGrid columns={2}>
            <Text style={titleStyle}>{t("tradingPhotoCardPage.matchedTradingPhotoCardBottomDrawerTitle")}</Text>
            <div style={{ justifySelf: "end" }}>
              <MeatBallMenus matchedTradingPhotoCardId={matchedTradingPhotoCard.matchedTradingPhotoCardId} onClickBlacklisted={onClickBlacklisted} />
            </div>
          </SimpleGrid>
        </DrawerHeader>
        <DrawerBody paddingX={"20px"}>
          <MatchedTradingPhotoCardInfo source={matchedTradingPhotoCard?.sourceCard} target={matchedTradingPhotoCard?.targetCard} />
          <div className="flex" style={{ paddingTop: "24px" }}>
            <div className="flex-none self-center" style={{ paddingRight: "8px" }}>
              <Box style={likeBoxStyle} onClick={() => onClickWished(matchedTradingPhotoCard.matchedTradingPhotoCardId)}>
                {
                  wished ? <LikeShapeIcon style={wishedIconStyle} fill={"#5C3DFA"} /> : <LikeLineIcon style={wishedIconStyle} fill={"#5C3DFA"} />
                }
              </Box>
            </div>
            <div className="flex-grow" onClick={() => navigateChatRoom()}>
              <PrimaryButton text={t("sendMessage")} style={buttonStyle} />
            </div>
          </div>
        </DrawerBody>
        <DrawerFooter className="safeAreaMarginBottom" width={"100%"} paddingTop={"10px"} paddingBottom={"0px"} paddingX={"20px"}>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

function MeatBallMenus({ matchedTradingPhotoCardId, onClickBlacklisted }) {
  const navigate = useNavigate();
  return (
    <Menu>
      <MenuButton>
        <Text fontSize={"18px"} fontWeight={600}>{"⋯"}</Text>
      </MenuButton>
      <MenuList>
        <MenuItem fontSize="14px" lineHeight="17px" fontWeight={500} onClick={() => onClickBlacklisted(matchedTradingPhotoCardId)}>{t("tradingPhotoCardPage.addBlackListMenu")}</MenuItem>
        <MenuItem fontSize="14px" lineHeight="17px" fontWeight={500} onClick={() => navigate(`/trading_reports?matchedTradingPhotoCardId=${matchedTradingPhotoCardId}`)}>{t("tradingPhotoCardPage.reportMenu")}</MenuItem>
      </MenuList>
    </Menu>
  )
}
