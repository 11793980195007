import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Stack,
  VStack
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import LightColors from "../../constants/LightColors";
import useExploreTradingPhotoCardsQueryV2 from "../api/queries/useExploreTradingPhotoCardsQueryV2";
import { ListRenderer } from "../lists/ListRenderer";
import GridSkeletons from "../skeletons/GridSkeletons";
import { first, isEmpty, isNull } from "lodash";
import RegularSolidButton from "../buttons/RegularSolidButton";
import { ellipsis } from "../../utils/TextUtil";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import usePhotoCardQuery from "../api/queries/usePhotoCardQuery";
import { TagsList } from "../photocard/TagsList";
import { useNavigate } from "react-router-dom";
import { openNewTab } from "../../utils/UIUtils";

// Styled Components
const DrawerTitleRow = styled.div`
  white-space: pre-wrap;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: ${LightColors.textmainlv1};
`;

const DrawerContentWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: calc(24px + env(safe-area-inset-bottom));
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${LightColors.separatorlv1};
`;

const PhotoCardSection = styled.div`
  flex-direction: row;
  display: flex;
`

const PhotoCardBox = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 16px;
  border: 1px solid ${LightColors.surface};
  border-radius: 12px;
  padding: 16px;
  width: 100%;
`

const PhotoCardName = styled.div`
  color: ${LightColors.textmainlv2};
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`

const EventName = styled.div`
  color: ${LightColors.textmainlv3};
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  margin-top: 2px;
`

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 4px;
  flex-wrap: wrap;
`

const EmptyText = styled.div`
  color: ${LightColors.onDisable};
  margin-top: 32px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
`

const CloseWrapper = styled.button`
  padding: 7px;
  z-index: 2147483647;
  border-radius: 50%;
  background: ${LightColors.surface};
`;

const NicknameText = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${LightColors.textmainlv3}
`;

const ResponseText = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  color: ${LightColors.textprimary};
`;

const PriceText = styled.div`font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${LightColors.textprimary}
`;

const RequestedAtText = styled.div`font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  color: ${LightColors.textsublv1};
`;

const TradingPhotoCardsByPhotoCardBottomDrawer = ({ artistId, photoCardId, isOpen, onClose, focusRef, onConfirm }) => {
  const navigate = useNavigate();
  const [paginatedTradingPhotoCards, setPaginatedTradingPhotoCards] = useState([])
  const { data: tradingPhotoCardsData, isFetching, fetchNextPage, hasNextPage } = useExploreTradingPhotoCardsQueryV2({artistId: artistId, photoCardId: photoCardId})
  const photoCard = usePhotoCardQuery(photoCardId);

  const handlePaginatedTradingPhotoCards = (paginatedData) => {
    const tradingPhotoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map((tradingPhotoCard) => tradingPhotoCard)
    )
    setPaginatedTradingPhotoCards(tradingPhotoCards);
  }

  const loadMore = () => {
    if(!isFetching && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    if (!isEmpty(tradingPhotoCardsData)) {
      handlePaginatedTradingPhotoCards(tradingPhotoCardsData);
    }
  }, [tradingPhotoCardsData]);


  const handleConfirm = () => {
    // onConfirm({});
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="bottom" finalFocusRef={focusRef}>
      <DrawerOverlay />
      <DrawerContent
        motionProps={{
          variants: {
            enter: {
              y: "0%",
              transition: { duration: 0.15 },
            },
            exit: {
              y: "100%",
              transition: { duration: 0.1 },
            },
          }
        }}
        style={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <DrawerHeader>
          <div className="flex flex-row items-center">
            <div className="flex-grow">
              <DrawerTitleRow>{"거래하고 싶은 상대방을 선택해주세요"}</DrawerTitleRow>
            </div>
            <CloseWrapper onClick={() => onClose()}>
              <CloseIcon width={"19px"} height={"19px"} fill={LightColors.textmainlv1}/>
            </CloseWrapper>
          </div>
          <PhotoCardSection>
          {
            isEmpty(photoCard) ? (
              <div className="w-full">
                <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={128}/>
              </div>
            ) : (
              <PhotoCardBox>
                <VStack
                  alignItems={"start"}
                  justify={"center"}
                  className={"flex-grow"}
                  gap={"0px"}
                >
                  <PhotoCardName>
                    {t("photoCardDetailPage.title", {memberName: photoCard.memberName, categoryName: photoCard.categoryName, categoryEmoji: photoCard.categoryEmoji})}
                  </PhotoCardName>
                  <EventName>
                    {photoCard.eventName}
                  </EventName>
                  <TagsWrapper>
                    <TagsList photoCard={photoCard}/>
                  </TagsWrapper>
                </VStack>
                <VStack
                  alignItems={"end"}
                  justify={"center"}
                  gap={"0px"}
                >
                  <div>{"평균가"}</div>
                  <div>{"3,000원"}</div>
                </VStack>
              </PhotoCardBox>
            )
          }
        </PhotoCardSection>
        </DrawerHeader>
        <DrawerBody padding={0} minHeight={"60vh"} height={"60vh"} maxHeight={"60vh"}>
        <DrawerContentWrapper>
          {
            paginatedTradingPhotoCards &&
            <motion.div
              initial={{ opacity: 0, y: -3 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -3 }}
              transition={{ duration: 0.3 }}
            >
            <ListRenderer
              data={paginatedTradingPhotoCards}
              emptyComponent={
                <EmptyText>{t("PhotoCardFilterBottomDrawer.emptyCategory")}</EmptyText>
              }
              skeletonComponent={
                <GridSkeletons
                  gridSize={1}
                  skeletonSize={10}
                  skeletonHeight={"48px"}
                  marginBottom={"8px"}
                />
              }
              renderList={(data) => (
                data.map((tradingPhotoCard) => (
                  <motion.div
                    key={tradingPhotoCard.tradingPhotoCardId}
                    initial={{ opacity: 0, y: -2 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -2 }}
                    transition={{ duration: 0.2 }}
                  >
                  <Row onClick={() => openNewTab(`/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}?photoCardId=${tradingPhotoCard.photoCard.photoCardId}`)}>
                    <Image
                      src={first(tradingPhotoCard.mediumThumbnailUrls) || tradingPhotoCard.photoCard.pictureUrl}
                      width={"104px"}
                      height={"104px"}
                      borderRadius={"12px"}
                      loading={"lazy"}
                      objectFit={"cover"}
                    />
                    <div className="flex flex-col flex-grow gap-2 items-start py-2">
                      <div className="flex gap-2">
                        <NicknameText>
                          {ellipsis(tradingPhotoCard.userProfile.nickname, 12)}
                        </NicknameText>
                        {
                          tradingPhotoCard.userProfile?.responseText &&
                          <ResponseText>
                            {tradingPhotoCard.userProfile.responseText}
                          </ResponseText>
                        }
                      </div>
                      <PriceText>
                        {isNull(tradingPhotoCard.price) ? t("tradingPhotoCardPage.offerPriceWithEmoji") : tradingPhotoCard.price}
                      </PriceText>
                      <RequestedAtText>
                        {tradingPhotoCard.requestedAt}
                      </RequestedAtText>
                    </div>
                    <RegularSolidButton text={t("select")}/>
                  </Row>
                  </motion.div>
                ))
              )}
            />
            </motion.div>
          }
        </DrawerContentWrapper>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default TradingPhotoCardsByPhotoCardBottomDrawer;
